<script setup>
// import axios from "@/gateway/backendapi";
import axios from "../public/baseurl/backendbaseurl";
import { ref } from "vue";
import { useStore } from "vuex";
import router from "../../../router";
import { useRoute } from "vue-router";
import { ElMessage } from "element-plus";

const email = ref("");
const password = ref("");
const fullName = ref("");
const phoneNumber = ref("");
const store = useStore();
const route = useRoute();
const policyAgree = ref(false);

const currentUser = ref(store.getters.currentUser);
const tenantId = ref(currentUser.value.tenantId);
console.log(tenantId.value, "hhsgsg");

const handleLogin = async () => {
  let payload = {
    email: email.value,
    password: password.value,
    phoneNumber: phoneNumber.value,
    name: fullName.value,
    tenantID: route.query.id,
  };
  console.log(payload, "Payload");

  if (policyAgree.value) {
    try {
      const { data } = await axios.post(
        "/mobile/v1/Account/WebSignUp",
        payload
      );
      console.log(data, "signup");
      if (data.status === true) {
        ElMessage({
          type: "success",
          showClose: true,
          message: data.response || "Account Created Successfully",
          duration: 10000,
        });
        // localStorage.setItem("signedUp", data.status);
        localStorage.setItem("loggedIn", data.status);
        store.dispatch("ecommerce/setLogin", data.status);
        localStorage.setItem("authToken", data.returnObject.token);
        localStorage.setItem("userId", data.returnObject.userId);
        router.push(`/store/home?id=${data.returnObject.tenantID}`);
      } else {
        ElMessage({
          type: "error",
          showClose: true,
          message: data.response,
          duration: 10000,
        });
      }
    } catch (error) {
      console.log(error);
      ElMessage({
        type: "error",
        showClose: true,
        message: "Failed, try again",
        duration: 10000,
      });
    }
  } else {
    policyAgree.value = false;
    ElMessage({
      type: "warning",
      showClose: true,
      message: "Please agree to our policy",
      duration: 10000,
    });
  }

  // Handle login logic here
};
</script>
<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-9 mb-5">
        <div class="col-md-12 d-flex text-center justify-content-center">
          <div class="s-36 text-dak">Create an account</div>
        </div>
        <div class="col-md-12 d-flex justify-content-center mb-3">
          <div class="s-20 fw-400">Order and Track it shipment easily</div>
        </div>
        <div class="card bg-gray-500 p-4">
          <div class="card-body">
            <div>
              <div class="form-group">
                <label class="fw-500 s-20 text-dak" for="email">Name</label>
                <el-input
                  type="text"
                  class="w-100"
                  v-model="fullName"
                  placeholder="Your name"
                  required
                />
              </div>
              <div class="form-group">
                <label class="fw-500 s-20 text-dak" for="email">Email</label>
                <el-input
                  type="email"
                  class="w-100"
                  v-model="email"
                  placeholder="Email Address"
                  required
                />
              </div>
              <div class="form-group">
                <label class="fw-500 s-20 text-dak" for="number"
                  >Phone Number</label
                >
                <el-input
                  type="number"
                  class="w-100"
                  v-model="phoneNumber"
                  placeholder="Phone"
                  required
                />
              </div>
              <div class="form-group">
                <label class="fw-500 s-20 text-dak" for="password"
                  >Create a Password</label
                >
                <el-input
                  type="password"
                  class="w-100"
                  v-model="password"
                  placeholder="Password"
                  required
                />
              </div>
              <div class="form-group d-flex">
                <span><el-checkbox v-model="policyAgree" /></span>
                <router-link
                  to="/termsofuse"
                  class="text-decoration-none ml-2 text-dak"
                  >By Creating an account, you agree with our terms and
                  conditions and Privacy Policies
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row justify-content-center">
            <div class="col-md-7 mt-5 mb-3">
              <el-button
                round
                @click="handleLogin"
                :disabled="!policyAgree"
                color="#FF5906"
                class="text-white w-100 py-4"
                >Create Account</el-button
              >
            </div>
          </div>
        </div>
        <div class="col-md-12 d-flex justify-content-center">
          <div class="text-center mt-3 mb-4">
            <router-link to="/store/login" target="_blank" class="fw-500 text-dak"
              >Already have an account?
              <span class="font-weight-bold">Login here</span></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
